import React, { ReactNode, useContext } from 'react'
import AuthContext from '../contexts/AuthContext'
import { NavbarMinimal } from './NavbarMinimal'


interface Props {
    children: ReactNode
}

function Main(props: Props) {
    const { children } = props
    const isAuthenticated = useContext(AuthContext)
    return (
        <div style={{ display: "flex", gap: "30px" }}>
            <NavbarMinimal />
            <div className="body-dashboard" >
                {children}
            </div>
        </div>
    )
}

export default Main