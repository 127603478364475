import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
} from '@mantine/core';
import { useState } from 'react';
import { login, signUp } from '../../apis/user.api';
import { useForm } from '@mantine/form';

export function SignUpForm() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const form = useForm({
        initialValues: { firstname: '', lastname: '', city: '', address: '', username: '', phone: '', password: '', confirmPassword: '' },

        // functions will be used to validate values at corresponding key
        validate: {
            firstname: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
            lastname: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
            username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value) => (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value) ? null : `
                    Has minimum 8 characters in length -
                    One letter uppercase -
                    One special character
            `),
            confirmPassword: (value, values) =>
                value !== values.password ? 'Passwords did not match' : null,

        },
    });
    return (
        <Container size={420} my={40}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={form.onSubmit((values) => {
                    const username = values.username
                    const phone = values.phone
                    const password = values.password
                    const firstname = values.firstname
                    const lastname = values.lastname
                    const address = values.address
                    const city = values.city
                    signUp({ firstname, lastname, phone, username, password, address, city });
                })}>
                    <div style={{
                        display: "flex",
                        gap: "12px"
                    }}>
                        <TextInput label="First name" placeholder="First Name" required
                            {...form.getInputProps('firstname')}
                        />
                        <TextInput label="Last name" placeholder="Last Name" required
                            {...form.getInputProps('lastname')}
                        />
                    </div>

                    <TextInput label="Email" placeholder="you@unitix.io" required
                        {...form.getInputProps('username')}
                    />

                    <TextInput label="Phone" placeholder="Phone"
                        {...form.getInputProps('phone')}
                    />
                    <TextInput label="Address" placeholder="Address"
                        {...form.getInputProps('address')}
                    />
                    <TextInput label="City" placeholder="City"
                        {...form.getInputProps('city')}
                    />

                    <PasswordInput label="Password" placeholder="Your password" required mt="md"
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput label="Confirm password" placeholder="Your password" required mt="md"
                        {...form.getInputProps('confirmPassword')}
                    />
                    <Button fullWidth mt="xl" sx={(theme) => ({
                        backgroundColor: theme.colors.orange[6], '&:hover': {
                            backgroundColor: theme.colors.orange[5],
                        },
                    })} type="submit" >
                        Sign Up
                    </Button>
                </form>

            </Paper>
        </Container>
    );
}