import { useContext, useEffect, useState } from 'react';
import { createStyles, Table, ScrollArea, Button, Modal, Pagination, MultiSelect, Notification } from '@mantine/core';
import Editor from '../Editor';
import i18n from '../../i18n';
import AuthContext from '../../contexts/AuthContext';
import dayjs from 'dayjs';
import { getGroupCompany } from '../../apis/group.api';
import { convertJsonToCsvAndDownload } from '../../utils/utilsApi';

const useStyles = createStyles((theme) => ({
    header: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease',

        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
                }`,
        },
    },

    scrolled: {
        boxShadow: theme.shadows.sm,
    },
}));



export function TableScrollArea({ filterStatus, sortAscending }: any) {

    const appContext = useContext(AuthContext);
    const { classes, cx } = useStyles();
    const [scrolled, setScrolled] = useState(false);
    const [opened, setOpened] = useState(false);
    const [data, setData] = useState({});
    const [demand, setDemand] = useState({});
    const roles: string[] | undefined = appContext?.roles ? appContext?.roles : [];
    const [groupSelected, setGroupSelected] = useState<string[]>([]);

    const [dataGroup, setDataGroup] = useState([{ value: '', label: '' }]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Nombre d'éléments à afficher par page


    let isAdmin = false;
    for (const admin of roles) {
        if (admin === "ROLE_COMPANY_ADMIN" || admin === "ROLE_ADMIN") {
            isAdmin = true;
        }

    }


    useEffect(() => {
        /* Récuperer les groupes de l'utilisateur connecté */
        getGroupCompany().then((response) => {
            const groupCompany = response.data;
            const groupCompanyArray: string[] = [];
            for (const group of groupCompany) {
                groupCompanyArray.push(group);
            }
            setGroupSelected(groupCompanyArray);
            /* Maping groupSelected for create object key value and setDataGroup */
            setDataGroup([])
            for (const group of groupCompanyArray) {
                setDataGroup((dataGroup: any) => [...dataGroup, { value: group, label: group }]);
            }

        });
    }, []);

    let listDemand: any[] | undefined = [];


    /* Voici l'explication du code ci-dessus:
    1. J'ai commencé par créer une variable appelée listDemand et je lui ai assigné le tableau appContext.demands.
    2. Ensuite, j'ai créé une condition pour vérifier si filterStatus est true ou false. Si false, listDemand est
    assigné au tableau appContext.demands. Si true,
    le listDemand est assigné au tableau appContext.demands mais filtré avec la condition que row.status === filterStatus.
    Cela signifie que seules les demandes dont le statut est égal à filterStatus seront affichées. */
    if (!groupSelected && !filterStatus) {
        listDemand = listDemand
    } else {
        listDemand = appContext?.demands.filter((row) => {

            //Fonction qui permet de filtrer les demandes par groupe et par statut
            //Si le groupe est dans le tableau groupSelected ET que le statut est égal à filterStatus
            //Alors tu retournes true, sinon tu retournes false
            if (
                groupSelected.includes(row.groupName) &&
                row.status === filterStatus
            ) {
                return true;
            } else if (
                groupSelected.includes(row.groupName) &&
                !filterStatus
            ) {
                return true;
            }
        })
    }


    /* Voici l'explication du code ci-dessus:
    1. Si listDemand et définie alors je crée une variable sortedDemands 
    2. Si sortAscending est true alors je trie le tableau listDemand par ordre croissant
    3. Si sortAscending est false alors je trie le tableau listDemand par ordre décroissant
    */
    if (typeof listDemand !== "undefined") {
        const sortedDemands: any[] | undefined = listDemand.sort((a: Demand, b: Demand) => {
            if (sortAscending) {
                return new Date(a.date.date).getTime() - new Date(b.date.date).getTime();
            } else {
                return new Date(b.date.date).getTime() - new Date(a.date.date).getTime();
            }
        });
    }

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };


    let rowsDemand: any[] | undefined = [];
    if (typeof listDemand !== "undefined") {
        rowsDemand = listDemand.map((row: Demand) => {
            return (
                <tr key={row.id}>
                    <td>{row.id}</td>
                    <td>{row.title}</td>
                    <td>{row.fullName}</td>
                    <td>{row.groupName}</td>
                    <td>{dayjs(row.date.date).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{row.status}</td>
                    <td>
                        <button className='showButton' onClick={() => {
                            setOpened(true)
                            setData(JSON.parse(row.name));
                            setDemand(row);
                        }}>{i18n.t("Show")}</button>
                    </td>

                </tr>
            );
        });
    }


    const totalPages = Math.ceil((rowsDemand?.length || 0) / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = rowsDemand.slice(startIndex, endIndex);


    return (
        <>

            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                fullScreen
                style={{
                    zIndex: 900,
                }}

            >
                <Editor data={data} demand={demand} modalClose={opened} setOpened={setOpened} />

            </Modal>
            {isAdmin ? <div style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                gap: "10px",
            }}> <MultiSelect
                    data={dataGroup}
                    label="Groupe"
                    placeholder="Selectionné un groupe"
                    value={groupSelected}
                    onChange={(value: string[]) => {
                        setGroupSelected(value)
                    }}
                />
                <Button
                    style={{
                        marginTop: "23px"
                    }}
                    onClick={() => {
                        convertJsonToCsvAndDownload(appContext?.demands, "FerreHotelDemandes");
                    }}
                > Télécharger les demandes en CSV</Button>
                
            </div> : null}
            <ScrollArea sx={{ height: "70vh" }} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
                <Table sx={{ minWidth: "100%" }} striped highlightOnHover withColumnBorders>
                    <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
                        <tr>
                            <th style={{
                                width: "5%",
                            }}>TicketId</th>
                            <th>{i18n.t("Desc")}</th>
                            <th>{i18n.t("Of")}</th>
                            <th>Groupe</th>
                            <th>{i18n.t("DateRequest")}</th>
                            <th>{i18n.t("Status")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{paginatedData ? paginatedData : null}</tbody>
                </Table>
                <Pagination defaultValue={currentPage} total={totalPages} size="sm" onChange={handlePageChange} />
            </ScrollArea>

        </>

    );
}