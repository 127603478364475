import React, { useState, useEffect, useContext, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import { Button, Title, Select, Modal, useMantineTheme, Group, Drawer, ActionIcon, Grid } from '@mantine/core';
import AuthContext from '../../contexts/AuthContext';
import { changeStatus, deleteDemand, demands, editDemand } from '../../apis/demands.api';
import { showNotification } from '@mantine/notifications';
import { IconMessages } from '@tabler/icons';
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import DragDrop from 'editorjs-drag-drop';
import io from 'socket.io-client';
import AttachesTool from '@editorjs/attaches';

import axios from 'axios';

import i18n from '../../i18n';
import { checkJwtExpiration } from '../../utils/utilsApi';
import { Chat } from '../Chat/Chat';
import { CardProfile } from '../CardProfile/CardProfile';
import PartnerPopup from '../../pages/Partner/PartnerPopup';
import EditorJSUnitix from './Editor';

const socket = io.connect("https://socket.unitix.io");


const Editor = (props) => {
    const appContext = useContext(AuthContext);
    const username = appContext.username;
    const [room, setRoom] = useState(props.demand.id);

    const joinRoom = () => {
        if (username !== "" && room !== "") {
            socket.emit("join_room", room)
        }
    }

    const [editor, seteditor] = useState({});
    const [opened, setOpened] = useState(false);
    const [openedModalSendMail, setOpenedModalSendMail] = useState(false);
    const [conversationDrawer, setConversationDrawer] = useState(false);

    const [editorChange, setEditorChange] = useState(false);
    const data = props.data
    const theme = useMantineTheme();
    useEffect(() => {
        checkJwtExpiration();
       
        const editorjs = new EditorJS(
            {
                /**
                 * Id of Element that should contain Editor instance
                */
               holder: "editorjs",
               autofocus: true,
                /**
                 * Available Tools list.
                 * Pass Tool's class or Settings object for each Tool you want to use
                 */
                tools: {
                    embed: Embed,
                    table: Table,
                    marker: Marker,
                    list: List,
                    warning: Warning,
                    code: Code,
                    linkTool: {
                        class: LinkTool,
                        config: {
                            endpoint: 'https://api.unitix.io/api/fetchUrl', // Your backend endpoint for url data fetching,
                        }
                    },
                    attaches: {
                        class: AttachesTool,
                        config: {
                            uploader: {
                                /**
                                 * Upload file to the server and return an uploaded image data
                                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                                 * @return {Promise.<{success, file: {url}}>}
                                */
                                async uploadByFile(file) {
                                    const formData = new FormData()
                                    formData.append('file', file)
                                    const result = await axios.post('https://api.unitix.io/api/uploadFile', formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                        },
                                    });

                                    return Promise.resolve(result.data);
                                }
                            }
                        }
                    },
                    image: {
                        class: Image,
                        config: {
                            uploader: {
                                /**
                                 * Upload file to the server and return an uploaded image data
                                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                                 * @return {Promise.<{success, file: {url}}>}
                                */
                                async uploadByFile(file) {
                                    const formData = new FormData()
                                    formData.append('image', file)
                                    const result = await axios.post('https://api.unitix.io/uploadImage', formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                        },
                                    });

                                    return Promise.resolve(result.data);
                                },
                                field: 'image',
                                types: 'image/*'
                            }
                        },
                    },
                    raw: Raw,
                    header: Header,
                    quote: Quote,
                    checklist: CheckList,
                    delimiter: Delimiter,
                    inlineCode: InlineCode,
                    simpleImage: SimpleImage,

                },
                onReady: () => {
                    new DragDrop(editorjs);
                },
                onChange: () => {
                    editorjs.save().then((outputData) => {
                        setEditorChange(true);
                    })
                },
                data: data

            }

        );
        seteditor(editorjs);

    }, [data]);

    
    const onSave = () => {
        checkJwtExpiration(); // Vérifie l'expiration du JWT (JSON Web Token)

        editor.save().then((outputData) => {
            // Enregistre le contenu de l'éditeur
            editDemand({
                id: props.demand.id,
                name: JSON.stringify(outputData)
            }).then(() => {
                // Met à jour la demande modifiée dans la base de données
                let getDatas;
                const req = async () => {
                    getDatas = await demands();
                    appContext.setDemands(getDatas.data); // Met à jour le contexte de l'application avec les nouvelles demandes
                };
                req();
                setEditorChange(false); // Définit l'état de la modification de l'éditeur à "false"
            }).catch(() => {
                showNotification({
                    title: `Vous n'avez pas l'autorisation`,
                    message: "Vous n'avez pas l'autorisation",
                    color: 'red',
                });
            });

        }).catch((error) => {
            showNotification({
                title: `Demand update error`,
                message: "Demand update error",
                color: 'red',
            });
        });
    };

    const onDelete = () => {
        // Supprime la demande spécifiée
        deleteDemand(props.demand.id).then(() => {
            setOpened(false);
            let getDatas;
            const req = async () => {
                getDatas = await demands();
                appContext.setDemands(getDatas.data); // Met à jour le contexte de l'application avec les nouvelles demandes
            };
            req();
            props.setOpened(false);
        }).catch(() => {
            showNotification({
                title: `Vous n'avez pas l'autorisation`,
                message: "Vous n'avez pas l'autorisation",
                color: 'red',
            });
        });
    };

    let isAdmin = false;
    const roles = appContext?.roles ? appContext?.roles : [];
    // Vérifie si l'utilisateur a un rôle administratif
    for (const role of roles) {
        if (role === "ROLE_COMPANY_ADMIN" || role === "ROLE_ADMIN" || role === "ROLE_EXPERT") {
            isAdmin = true;
        }
    }

    const onChange = (status) => {
        // Change le statut de la demande spécifiée
        changeStatus(status, props.demand.id).then(() => {
            let getDatas;
            const req = async () => {
                getDatas = await demands();
                appContext.setDemands(getDatas.data); // Met à jour le contexte de l'application avec les nouvelles demandes
            };
            req();
        }).catch(() => {
            showNotification({
                title: `Vous n'avez pas l'autorisation`,
                message: "Vous n'avez pas l'autorisation",
                color: 'red',
            });
        });
    };
    const ref = useRef(null);

    return (
        <div>
            {/* Modal pour confirmer la suppression de la demande */}
            <Modal
                overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                overlayOpacity={0.55}
                overlayBlur={3}
                opened={opened}
                title="Voulez vous vraiment supprimer la demande"
            >
                <Group position="center">
                    <Button onClick={() => setOpened(false)}>{i18n.t("Cancel")}</Button>
                    <Button color="red" onClick={() => onDelete()}>{i18n.t("delete")}</Button>
                </Group>
            </Modal>
            {/* Modal pour envoyer un mail à un partenaire */}
            <Modal
                overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                overlayOpacity={0.55}
                overlayBlur={3}
                onClose={() => setOpenedModalSendMail(false)}
                opened={openedModalSendMail}
                title="Envoyer un mail à un paretnaire pour leur transmettre la demande"
                size="auto"
            >
                <PartnerPopup demand={props.demand} dataEditor={data} editorChange={editorChange} openedModalSendMail={openedModalSendMail} setOpenedModalSendMail={setOpenedModalSendMail}  />   
            </Modal>
            {/* Drawer pour la conversation */}
            <Drawer
                opened={conversationDrawer}
                onClose={() => setConversationDrawer(false)}
                title="Conversation"
                overlayProps={{ opacity: 0.5, blur: 4 }}
                position="right"
                padding="xl"
                size="xl"
                transition="slide-left"
                transitionDuration={250}
                transitionTimingFunction="ease"
            >
                <Chat socket={socket} username={username} room={room} />
            </Drawer>
            {/* Titre et actions */}
            <div style={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                position: "justify",
                zIndex: 999,
            }}>
                <Title size="2.8em" color={appContext?.company?.companyColor ? appContext?.company?.companyColor : "orange.5"} style={{
                    fontWeight: 600,
                    fontFamily: "Poppins"
                }}>{i18n.t("editDemand")}</Title>
                {/* Boutons d'action */}
                
            </div>
            {/* Conteneur de l'éditeur */}
            <Grid>
                <Grid.Col sm={12} md={2} lg={2} xl={2}>
                    <CardProfile avatar="https://images.unsplash.com/photo-1623582854588-d60de57fa33f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80" fullName={props.demand.fullName} groupName={props.demand.groupName} />   
                        <Button.Group orientation='vertical' style={{
                            marginTop: "20px",
                        }}>
                            <Button color="red" onClick={() => setOpened(true)}>{i18n.t("delete")}</Button>
                            <Button color="green" onClick={() => onSave()}>{i18n.t("save")}</Button>
                            <Select
                                placeholder={props.demand.status}
                                defaultValue={props.demand.status}
                                onChange={(status) => onChange(status)}
                                data={[
                                    { value: 'NOUVEAU', label: 'Nouveau' },
                                    { value: 'OUVERT', label: 'Ouvert' },
                                    { value: 'RESOLU', label: 'Résolu' },
                                    { value: 'CLOTURE', label: 'Cloturé' },
                                    { value: 'ARCHIVE', label: 'Archivé' },
                                    { value: 'PROVECTIO_OPEN', label: 'Provectio Ouvert' },
                                    { value: 'PROVECTIO_CLOSE', label: 'Provectio Fermé' }
                                ]}
                            />
                            <Button color="blue" onClick={() => setOpenedModalSendMail(true)}>Envoyer</Button>
                        
                        {/* Icône pour ouvrir la conversation */}
                        
                        <ActionIcon
                            sx={{
                                '&[data-disabled]': { opacity: 0.4 },
                                '&[data-loading]': { backgroundColor: 'red' }
                            }}
                            onClick={() => {
                                if (username !== "" && room !== "") {
                                    joinRoom();
                                    setConversationDrawer(true);
                                }
                            }}>
                            <IconMessages size={32} color="orange" />
                        </ActionIcon>
                    </Button.Group>
                            
                </Grid.Col>
                <Grid.Col sm={12} md={10} lg={10} xl={10}>
                    <div id="editorjs" />
                </Grid.Col>
            </Grid>
        </div>
    );
};
export default Editor;
