import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Stepper,
    Button,
} from '@mantine/core';
import { useState } from 'react';
import { forgotPassword, newPassword } from '../../apis/user.api';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import i18n from '../../i18n';

export function ForgotPasswordForm() {
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const formForgotSendMail = useForm({
        initialValues: { username: '' },

        // functions will be used to validate values at corresponding key
        validate: {
            username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email')
        },

    });

    const formForgotNewPass = useForm({
        initialValues: { password: '', token: '' },

        // functions will be used to validate values at corresponding key
        validate: {
            token: (value) => (/^\S/.test(value) ? null : 'Invalid Token'),
            password: (value) => (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value) ? null : `
            Has minimum 8 characters in length -
            One letter uppercase -
            One special character
      `),
        },

    });

    return (
        <Container size={420} my={40}>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Stepper active={step} breakpoint="md">
                    <Stepper.Step label="Step 1" description="Verify Email">
                        <form onSubmit={
                            formForgotSendMail.onSubmit((values) => {
                                const username = values.username
                                forgotPassword(username).then(() => setStep(1)).catch((e) => {
                                    showNotification({
                                        title: i18n.t("TitleForgotPassNotif"),
                                        message: i18n.t("MessageForgotPassNotif"),
                                        color: 'red',
                                    });
                                })
                            })
                        }>
                            <TextInput label="Email" placeholder="you@unitix.io" required
                                {...formForgotSendMail.getInputProps('username')}
                            />
                            <Button fullWidth mt="xl" sx={(theme) => ({
                                backgroundColor: theme.colors.orange[6], '&:hover': {
                                    backgroundColor: theme.colors.orange[5],
                                },
                            })} type="submit">
                                Send
                            </Button>
                        </form>

                    </Stepper.Step>

                    <Stepper.Step label="Step 2" description="Valide code" loading>
                        <form onSubmit={
                            formForgotNewPass.onSubmit((values) => {
                                const token = values.token
                                const newPass = values.password
                                newPassword(token, newPass).then(() => {
                                    showNotification({
                                        title: i18n.t("TitlePassChanged"),
                                        message: i18n.t("MessagePassChanged"),
                                        color: 'green',
                                    });
                                    setTimeout(() => {

                                        navigate("/login");
                                    }, 2000);
                                }
                                ).catch((e) => {
                                    showNotification({
                                        title: i18n.t("TitleCodeChange"),
                                        message: i18n.t("MessageCheckCode"),
                                        color: 'red',
                                    });
                                })
                            })
                        }>
                            <TextInput label="Code" placeholder="Code" required
                                {...formForgotNewPass.getInputProps('token')}
                            />
                            <PasswordInput label="New Password" placeholder="Your new password" required
                                {...formForgotNewPass.getInputProps('password')}
                            />
                            <Button fullWidth mt="xl" sx={(theme) => ({
                                backgroundColor: theme.colors.orange[6], '&:hover': {
                                    backgroundColor: theme.colors.orange[5],
                                },
                            })} type="submit">
                                Send
                            </Button>
                        </form>
                    </Stepper.Step>

                </Stepper>
            </Paper>
        </Container >
    );
}