import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';
import { PATH } from '../constants/paths'
import { addItem, checkJwtExpiration, getItem, removeItem } from '../utils/utilsApi';
import i18n from '../i18n';
import { OpenAIApi, Configuration } from 'openai';

export async function login(user: ReqLogin) {
    const response = await axios
        .post(PATH.API + "login", user)
        .then((data) => {
            const token = data.data.token;
            addItem('token', token);
            getProfile();
            window.location.reload();
        })
        .catch((e) => {
            showNotification({
                title: i18n.t("TitleLoginNotif"),
                message: i18n.t("MessageLoginNotif"),
                color: 'red',
            });
        })

    return response;
}


export async function signUp(user: SignUpUser) {
    const response = await axios
        .post(PATH.API + "register", user)
        .then(() => {
            showNotification({
                title: i18n.t("TitleSignUpNotif"),
                message: i18n.t("MessageSignUpNotif"),
                color: 'green',
                loading: true
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
        .catch((e) => {
            if (e.status !== 200) {
                showNotification({
                    title: i18n.t("TitleErrorSignUpNotif"),
                    message: i18n.t("MessageErrorSignUpNotif"),
                    color: 'red',
                });
            }

        })

    return response;
}

export async function updateProfile(user: any) {
    checkJwtExpiration();
    const response = await axios
        .put(PATH.API + "update/profile", user, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleUpdateProfile"),
                message: i18n.t("MessageUpdateProfile"),
                color: 'green',
                loading: true
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
        .catch((e) => {
            if (e.status !== 200) {
                showNotification({
                    title: i18n.t("TitleErrorUpdateProfile"),
                    message: i18n.t("MessageErrorUpdateProfile"),
                    color: 'red',
                });
            }

        })

    return response;
}

export async function forgotPassword(mail: String) {
    const response = await axios
        .post(PATH.PATH + "resetPassword", {
            username: mail
        })

    return response;
}

export async function newPassword(token: String, newPass: String) {
    const response = await axios
        .post(PATH.PATH + "newPassword", {
            token,
            newPass
        })

    return response;
}


export async function getProfile() {
    checkJwtExpiration();

    const profile = await axios
        .get<Profile>(PATH.API + "profile", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .catch((e) => {
            showNotification({
                title: i18n.t("TitleErrorGetProfile"),
                message: i18n.t("MessageErrorGetProfile"),
                color: 'red',
            });
        });
    return profile;
}

export async function getCompany() {
    checkJwtExpiration();

    const company = await axios
        .get<Company>(PATH.API + "company", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })

    return company;
}

export async function sendFeedback(comment: Feedback) {
    checkJwtExpiration();

    const response = await axios
        .post(PATH.API + "feedback", comment, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleNotifFeedBack"),
                message: i18n.t("MessageNotifFeedBack"),
                color: 'green'
            });
        })

    return response;


}


export async function getResponseAI(conversation: any): Promise<string | undefined> {
    checkJwtExpiration();

    //config openai with question and token
    const openaiConfig = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_KEY,
    });
    const openai = new OpenAIApi(openaiConfig)
    try {
        const response: any | undefined = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: conversation
        })
        return response.data.choices[0].message.content || undefined

    } catch (error) {
        return undefined
    }
}