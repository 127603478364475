import { Button } from '@mantine/core'
import logo from '../assets/TexteNoir.png'
import { IconArrowBackUp, IconUser } from '@tabler/icons';
import i18n from '../i18n';
export default function Header() {
    return (
        <div style={{
            padding: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center"
        }}>
            <a href="https://ferrehotels.unitix.io/">
                <img src={logo} alt="" width="250px" />
            </a>
            <div style={{
                display: "flex",
                gap: "10px"
            }}>
                <Button component="a" mt="xl" href="https://ferrehotels.unitix.io/" sx={(theme) => ({
                    backgroundColor: theme.colors.orange[6], '&:hover': {
                        backgroundColor: theme.colors.orange[5],
                    },
                })}
                    leftIcon={<IconArrowBackUp />}
                    size="sm"
                >
                    Back to website
                </Button>
                <Button component="a" href="https://www.unitix.io/page-contact/" mt="xl" sx={(theme) => ({
                    backgroundColor: theme.colors.orange[6], '&:hover': {
                        backgroundColor: theme.colors.orange[5],
                    },
                })}
                    leftIcon={<IconUser />}
                    size="sm"
                >
                    {i18n.t("ContactUs")}
                </Button>

            </div>

        </div>
    )
}
