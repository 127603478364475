import React, { useContext, useState } from "react"
import { Anchor, Text, Title } from "@mantine/core"
import { Navigate, useNavigate } from "react-router-dom"
import { AuthenticationTitle } from "../../components/Login/LoginForm"
import { SignUpForm } from "../../components/SignUp/SignUpForm"
import Header from "../../layouts/Header"


const Login = () => {
    const [isFormLogin, seTisFormLogin] = useState(true);


    return (
        <div className="container" style={{
        }}>
            {<Header />}
            <div className="min-vh-100 row">
                <Title
                    align="center"
                    size="2.5em"

                >
                    {isFormLogin ? "Login" : "Sign Up"}
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    {isFormLogin ? "Do not have an account yet?" : "Have an account?"}{' '}
                    <Anchor<'a'> href="#" size="sm" onClick={() => seTisFormLogin(!isFormLogin)}>
                        {isFormLogin ? "Create account" : "Log in"}
                    </Anchor>
                </Text>
                {isFormLogin ? <AuthenticationTitle /> : <SignUpForm />}
            </div>
        </div>
    )
}

export default Login
