import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { PATH } from '../constants/paths'
import i18n from '../i18n';
import { checkJwtExpiration, getItem } from '../utils/utilsApi';



export function addPartner(partner: Partner) {
    checkJwtExpiration();
    const response = axios
    .post(PATH.API + "partner/add", partner, {
        headers: {
            Authorization: 'Bearer ' + getItem("token")
        }
    }).then(() => {
        showNotification({
            title: i18n.t("AddDemand"),
            message: i18n.t("MessageAddDemand"),
            color: 'green',
        });
    })

    return response;

}

export async function allPartner() {
    checkJwtExpiration();
    const response = await axios
        .get(PATH.API + "partner", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .catch((e) => {
            showNotification({
                title: i18n.t("NoAuthorize"),
                message: i18n.t("MessageNoAuthorize"),
                color: 'red',
            });
        });
    return response;
}

export function sendMailPartner(demandId: any, status: string, partnerMail: string, demandToPartner: any) {
    checkJwtExpiration();
    const response = axios
        .post(PATH.API + "notif/partner/", {
            id: demandId,
            status: status,
            partnerMail: partnerMail,
            demandToPartner: JSON.stringify(demandToPartner)
        }, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        }).then(() => {
            showNotification({
                title: i18n.t("SendMailPartner"),
                message: i18n.t("MessageSendMailPartner"),
                color: 'green',
            });
        }
        )
    return response;
}
