import { Paper, Title as MantineTitle } from '@mantine/core'
import React, { Children, cloneElement, FC, ReactElement, ReactNode } from 'react'


const Title: React.FC<{children: React.ReactNode}> = ({ children }) => {
  return <h2>{children}</h2>;
};


const Content: React.FC<{children: React.ReactNode}> = ({ children }) => {
  return <div className="modal-content">{children}</div>;
};


/**
 * Fonction utilitaire permettant de trouver un noeud enfant correspondant à un type donné
 */
function findSlotOfType<T extends ReactElement>(
  children: ReactNode,
  slotType: React.FC<{children: React.ReactNode}>
): T | undefined {
  return Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === slotType
  ) as T | undefined;
}


const CardPaper: FC<{ children: ReactNode }> = ({ children }) => {
  
  // Récupération de chaque slot : 
  const TitleComponent = findSlotOfType(children, Title);
  const ContentComponent = findSlotOfType(children, Content);


  return <Paper withBorder shadow="md" p={30} radius="md" style={{
    border: "1px solid orange"
}}>

    {TitleComponent}
    {ContentComponent}
  </Paper>;
};


export default Object.assign(CardPaper, { Title: Title, Content: Content });
