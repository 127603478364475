import { useEffect, useState } from "react"
import { Grid, ScrollArea, Table, Title } from "@mantine/core"
import { motion } from "framer-motion"
import CardPaper from "../../components/Card/CardPaper"
import PartnerForm from "./PartnerForm"
import { allPartner } from "../../apis/partner.api"


const Partner = () => {
    const [listPartner, setListPartner] = useState([]);
    const [fetchData, setFetchData] = useState(true);

    useEffect(() => {
        let getDatas: any;
        const req = async () => {
            getDatas = await allPartner();
            setListPartner(getDatas.data.data);
        };
        req();
    }, [fetchData])


    const handleSend = () => {
        setFetchData(!fetchData);
    }

    const rows = listPartner.map((partner: Partner) => (
        <tr key={partner.id}>
            <td>{partner.id}</td>
            <td>{partner.name}</td>
            <td>{partner.mail}</td>
            <td>{partner.logo ? <img src={partner.logo.toString()} width="100px" alt="logo" /> : null}</td>
        </tr>
    ));


    return (
        <div className="container" style={{
        }}>
            <Title size="3.5em" style={{
                fontWeight: 600,
                fontFamily: "Poppins"
            }}>Gestion des partenaires</Title>
            <Grid grow style={{
                marginRight: "18px",
            }} >

                <Grid.Col span={4}>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.2 }}
                    >
                        <CardPaper>
                            <CardPaper.Title>
                                <Title
                                    align="left"
                                    style={{
                                        fontWeight: 900,
                                        fontFamily: "Poppins"
                                    }}
                                >
                                    Ajouter un partenaire
                                </Title>
                            </CardPaper.Title>

                            <CardPaper.Content>
                                <PartnerForm action={handleSend} />
                            </CardPaper.Content>
                        </CardPaper>

                    </motion.div>


                </Grid.Col>
                <Grid.Col span={8}>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.4 }}
                    >
                        <CardPaper>
                            <CardPaper.Title>
                                <Title
                                    align="left"
                                    style={{
                                        fontWeight: 900,
                                        fontFamily: "Poppins"
                                    }}
                                >
                                    Liste des partenaires
                                </Title>
                            </CardPaper.Title>

                            <CardPaper.Content>
                                <ScrollArea h={450}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>id</th>
                                                <th>Nom</th>
                                                <th>Mail</th>
                                                <th>Logo</th>
                                            </tr>
                                        </thead>
                                        <tbody>{rows}</tbody>
                                    </Table>
                                </ScrollArea>
                            </CardPaper.Content>
                        </CardPaper>
                    </motion.div>

                </Grid.Col>
            </Grid>

        </div>
    )
}

export default Partner
