import {
    TextInput,
    Checkbox,
    Paper,
    Title,
    Text,
    Group,
    ColorPicker,
    FileButton,
    Divider,
    Input,
    Table, Select, ScrollArea,
    Button, Modal
} from '@mantine/core';
import { IconUsers, IconX, IconPlus } from '@tabler/icons';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { addGroupInCompany, addGroupInUser, deleteGroup, getAllGroup, getAllGroupData, getInvitedEmployee, getUsersCompany, inviteEmploye, updateCompany, updateGroup, updateGroupOfUser } from '../../apis/company.api';
import AuthContext from '../../contexts/AuthContext';
import i18n from '../../i18n';


export function UsersRolesTable() {
    const [rolesData, setRolesData] = useState([]);
    const [data, setDataUsers]: any = useState([]);
    const [invitedEmployee, setInvitedEmployee]: any = useState([]);
    const appContext = useContext(AuthContext);

    useEffect(() => {
        let getDatas: any;
        let getRolesName: any;
        let invitedEmployee: any;
        const req = async () => {
            getDatas = await getUsersCompany()
            getRolesName = await getAllGroup();
            invitedEmployee = await getInvitedEmployee();
            setRolesData(getRolesName.data);
            setDataUsers(getDatas.data);
            setInvitedEmployee(invitedEmployee.data);
        }
        req();
    }, [])


    //TODO Refactor create function global for check isAdmin
    const roles: string[] | undefined = appContext?.roles ? appContext?.roles : [];
    let isAdmin = false;
    for (const admin of roles) {
        if (admin === "ROLE_COMPANY_ADMIN" || admin === "ROLE_ADMIN") {
            isAdmin = true;
        }
    }

    const invitedUsers = invitedEmployee.map((item: any) => (
        <tr key={item.guestMail}>
            <td>
                <Group spacing="sm">
                    <div>
                        <Text size="sm" weight={400}>
                            {item.guestMail}
                        </Text>
                    </div>
                </Group>
            </td>
        </tr>
    ));


    const rows = data.map((item: any) => (
        <tr key={item.email}>
            <td>
                <Group spacing="sm">

                    <div>
                        <Text size="sm" weight={500}>
                            {item.name}
                        </Text>
                        <Text size="xs" color="dimmed">
                            {item.email}
                        </Text>
                    </div>
                </Group>
            </td>

            <td>
                {isAdmin === true ? <Select data={rolesData} defaultValue={item.role} onChange={(groupName) => {
                    if (item.role === null) {
                        addGroupInUser(groupName, item.id)
                    } else {
                        updateGroupOfUser(groupName, item.id);
                    }
                }} variant="unstyled" /> : <h4>{item.role}</h4>}

            </td>
        </tr>
    ));

    return (
        <div>
            <ScrollArea sx={{ height: "30vh" }} >
                <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
                    <thead>
                        <tr>
                            <th>{i18n.t("Employee")}</th>
                            <th>{i18n.t("Group")}</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </ ScrollArea>
            <ScrollArea sx={{ height: "30vh" }} >
                <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
                    <thead>
                        <tr>
                            <th>{i18n.t("TextInvitedEmployee")}</th>
                        </tr>
                    </thead>
                    <tbody>{invitedUsers}</tbody>
                </Table>
            </ ScrollArea>
        </div>

    );
}

export function Roles() {
    const [groupsData, setGroupsData] = useState([]);
    let getGroupsDatas: any;
    const req = async () => {
        getGroupsDatas = await getAllGroupData()
        setGroupsData(getGroupsDatas.data)
    }
    useEffect(() => {

        req();
    })

    const [name, setName] = useState("");

    const group = {
        name,
        grantedAccess: []
    }

    const rows = groupsData.map((item: any) => (
        <tr key={item.name}>
            <td>
                <Group spacing="sm">

                    <div>
                        <Text size="sm" weight={500}>
                            {item.name}
                        </Text>
                    </div>
                </Group>
            </td>


            <td>
                <Checkbox defaultChecked={item.grantedAccess[0] === "SHOw_DEMANDS"} onChange={(e) => {
                    let arrayGrantedAccess = item.grantedAccess;
                    if (e.target.value === "on" && item.grantedAccess[0] === "SHOw_DEMANDS") {
                        arrayGrantedAccess[0] = ""
                    } else {
                        arrayGrantedAccess[0] = "SHOw_DEMANDS";
                    }
                    updateGroup(item.id, {
                        name: item.name,
                        grantedAccess: arrayGrantedAccess
                    })
                }} />
            </td>
            <td>
                <Checkbox defaultChecked={item.grantedAccess[1] === "EDIT_DEMANDS"} onChange={(e) => {
                    let arrayGrantedAccess = item.grantedAccess;
                    if (e.target.value === "on" && item.grantedAccess[1] === "EDIT_DEMANDS") {
                        arrayGrantedAccess[1] = ""
                    } else {
                        arrayGrantedAccess[1] = "EDIT_DEMANDS";
                    }
                    updateGroup(item.id, {
                        name: item.name,
                        grantedAccess: arrayGrantedAccess
                    })
                }} />
            </td>
            <td>
                <Checkbox defaultChecked={item.grantedAccess[2] === "DELETE_DEMANDS"} onChange={(e) => {
                    let arrayGrantedAccess = item.grantedAccess;
                    if (e.target.value === "on" && item.grantedAccess[2] === "DELETE_DEMANDS") {
                        arrayGrantedAccess[2] = ""
                    } else {
                        arrayGrantedAccess[2] = "DELETE_DEMANDS";
                    }
                    updateGroup(item.id, {
                        name: item.name,
                        grantedAccess: arrayGrantedAccess
                    })
                }} />
            </td>
            <td>
                <Button.Group>

                    <Button style={{
                        backgroundColor: "#F5554A"
                    }} onClick={() => {
                        deleteGroup(item.id).then(() => {
                            req();
                        })
                    }}>
                        <IconX size={18} />
                    </Button>
                </Button.Group>
            </td>
        </tr>
    ));
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-arround"
        }}>
            <ScrollArea style={{ height: 350 }}>
                <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
                    <thead>
                        <tr>
                            <th>{i18n.t("Name")}</th>
                            <th>{i18n.t("ShowDemands")}</th>
                            <th>{i18n.t("EditDemands")}</th>
                            <th>{i18n.t("DeleteDemands")}</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </ScrollArea>
            <Paper withBorder shadow="md" p={30} radius="md" style={{
                border: "1px solid orange",
                marginTop: "40px"
            }}>
                <Title
                    align="left"
                    sx={(theme) => ({ fontWeight: 900, fontFamily: "Poppins" })}
                >
                    {i18n.t("AddGroup")}
                </Title>
                <div style={{
                    display: "flex",
                    gap: "20px"
                }}>
                    <Input
                        placeholder={i18n.t("Name")}
                        onChange={(e: any) => {
                            setName(e.target.value)

                        }}
                    />
                    <Button onClick={() => {
                        if (name) {
                            addGroupInCompany(group).then(() => {

                                req();
                            });
                        }
                    }}>{i18n.t("Add")}</Button>

                </div>

            </Paper>

        </div>

    )
}


function Company() {
    const appContext = useContext(AuthContext);
    const [file, setFile] = useState<File | null>(null);
    const [name, setName] = useState(appContext?.company?.companyName);
    const [address, setAddress] = useState(appContext?.company?.companyAdress);
    const [siret, setSiret] = useState(appContext?.company?.companySiret);
    const [guestMail, setGuestMail] = useState("");
    const [groupName, setGroupName] = useState("");
    const resetRef = useRef<() => void>(null);
    const [rolesData, setRolesData] = useState([]);

    useEffect(() => {
        let getRolesName: any;
        const req = async () => {
            getRolesName = await getAllGroup();
            setRolesData(getRolesName.data);
        }
        req();
    }, [])

    const clearFile = () => {
        setFile(null);
        resetRef.current?.();
    };
    const [opened, setOpened] = useState(true);
    const [openedModalAddEmploye, setOpenedModalEmploye] = useState(false);

    const [value, onChange] = useState(appContext?.company?.companyColor);


    const companyUpdate = {
        name,
        address,
        siret,
        color: value
    }

    const handleUpdateCompany = () => {
        updateCompany(companyUpdate);
    }
    const roles: string[] | undefined = appContext?.roles ? appContext?.roles : [];
    let isAdmin = false;
    for (const admin of roles) {
        if (admin === "ROLE_COMPANY_ADMIN") {
            isAdmin = true;
        }
    }
    const height: string = isAdmin === false ? "80vh" : "";
    return (
        <div>
            <Title size="3.5em" style={{
                fontWeight: 600,
                fontFamily: "Poppins"
            }}>{isAdmin === true ? i18n.t("ManageCompany") : i18n.t("Company")}</Title>
            <div style={{
                display: "flex",
                gap: "10px"
            }}>
                {isAdmin === true ? <Paper withBorder shadow="md" p={30} radius="md" style={{
                    border: "1px solid orange",
                    width: "30%",
                    height: "80vh"
                }}>
                    <Title
                        align="left"
                        sx={(theme) => ({ fontWeight: 900, fontFamily: "Poppins" })}
                    >
                        {i18n.t("InfoCompany")}
                    </Title>
                    <TextInput id="nameCompany" label="Nom" placeholder="" defaultValue={appContext?.company?.companyName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setName(e.target.value);

                    }} required />
                    <TextInput id="companyAddress" label={i18n.t("Adresse")} placeholder="" defaultValue={appContext?.company?.companyAdress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAddress(e.target.value);

                    }} required />
                    <TextInput id="nbCompany" label="Siret" placeholder="" defaultValue={appContext?.company?.companySiret} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSiret(e.target.value);

                    }} required />
                    {/**
                     * 
                     * <Text>Logo</Text>
                    <Group position="center">
                        <FileButton resetRef={resetRef} onChange={setFile} accept="image/png,image/jpeg">
                            {(props) => <Button {...props}>Upload image</Button>}
                        </FileButton>
                        <Button disabled={!file} color="red" onClick={clearFile}>Reset</Button>
                    </Group>
                     */}

                    {file && (
                        <Text size="sm" align="center" mt="sm">
                            Picked file: {file.name}
                        </Text>
                    )}
                    <Divider my="xs" label={i18n.t("ChooseColor")} />
                    <ColorPicker
                        format="hex"
                        value={value} onChange={onChange}
                        swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                        style={{
                            width: "100%"
                        }}
                    />

                    <Button style={{
                        marginTop: "40px"
                    }} onClick={() => handleUpdateCompany()} fullWidth >{i18n.t("Update")}</Button>
                </Paper> : null}

                <Paper withBorder shadow="md" p={30} radius="md" style={{
                    border: "1px solid orange",
                    width: "80%",
                    height: height,
                    marginRight: "20px"
                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Modal
                            opened={openedModalAddEmploye}
                            onClose={() => setOpenedModalEmploye(false)}
                            title={i18n.t("TextInvitationEmploye")}
                            centered
                        >
                            <TextInput label={i18n.t("AdresseMail")} placeholder={i18n.t("AdresseMail") || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setGuestMail(e.target.value);

                            }} />
                            <Select style={{
                                border: "1px solid #CED4DA",
                                marginTop: "2px",
                                borderRadius: "4px",
                                paddingLeft: "12px",
                                marginBottom: "2px"
                            }} data={rolesData} variant="unstyled" placeholder={i18n.t("ChooseGroup") || ""} onChange={(groupName: any) => {
                                setGroupName(groupName);

                            }} />
                            <Button fullWidth variant="filled" onClick={() => {
                                inviteEmploye(guestMail, groupName);
                                setGuestMail("");
                                setGroupName("");
                                setOpenedModalEmploye(false)
                            }}>
                                {i18n.t("SendInvitation")}
                            </Button>
                        </Modal>
                        <Title
                            align="left"
                            sx={(theme) => ({ fontWeight: 900,fontFamily: "Poppins" })}
                        >
                            {i18n.t("Members")}
                        </Title>
                        {isAdmin === true ? <Button.Group>

                            <Button onClick={() => setOpened(false)} variant={opened === true ? "filled" : "outline"} leftIcon={<IconUsers size={14} />}>{i18n.t("ManageGroup")}</Button>
                            <Button onClick={() => setOpened(true)} variant={opened ? "outline" : "filled"} leftIcon={<IconUsers size={14} />}>{i18n.t("ShowMembers")}</Button>
                            <Button style={{
                                backgroundColor: "#ff8000"
                            }} onClick={() => setOpenedModalEmploye(true)} leftIcon={<IconPlus size={14} />}>{i18n.t("AddEmployee")}</Button>
                        </Button.Group> : null}


                    </div>
                    {isAdmin === true ? opened ? <UsersRolesTable /> : <Roles /> : <UsersRolesTable />}


                </Paper>
            </div >


        </div >
    )
}

export default Company