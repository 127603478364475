import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import DragDrop from 'editorjs-drag-drop';
import AttachesTool from '@editorjs/attaches';

export const EDITOR_TOOLS = {
    code: Code,
    header: Header,
    embed: Embed,
    table: Table,
    list: List,
    warning: Warning,
    linkTool: LinkTool,
    image: Image,
    raw: Raw,
    quote: Quote,
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    attaches: AttachesTool,
    dragDrop: DragDrop
  };