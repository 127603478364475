import axios from "axios";
import { checkJwtExpiration, getItem } from "../utils/utilsApi";
import { PATH } from "../constants/paths";

export async function getGroupCompany() {
    checkJwtExpiration();

    const response = axios
        .get(PATH.API + "group/all", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })

    return response;
}