import { useContext, useState } from 'react';
import { Navbar, Center, Tooltip, UnstyledButton, createStyles, Stack, Modal, Textarea, Button } from '@mantine/core';
import {
    TablerIcon,
    IconGauge,
    IconHelp,
    IconUser,
    IconLanguage,
    IconLogout,
    IconBriefcase,
    IconArticle
} from '@tabler/icons';
import logo from '../assets/LogoSeulNoir.png'
import { useNavigate } from "react-router-dom";
import AuthContext from '../contexts/AuthContext';
import i18n from '../i18n';
import { addItem } from '../utils/utilsApi';
import { sendFeedback } from '../apis/user.api';
const useStyles = createStyles((theme) => ({
    link: {
        width: 50,
        height: 50,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
    },

    active: {
        '&, &:hover': {
            backgroundColor: theme.fn.rgba(theme.colors.orange[1], 0.35),
            color: theme.colors.gray[7],
        },
    },
}));

interface NavbarLinkProps {
    icon: TablerIcon;
    label: string;
    active?: boolean;
    onClick?(): void;
}
function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
    return (
        <Tooltip label={label} position="right" >
            <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
                <Icon stroke={1.5} />
            </UnstyledButton>
        </Tooltip>
    );
}



export function NavbarMinimal() {
    const [active, setActive] = useState(0);
    const navigate = useNavigate();
    const [mockData, setMockData] = useState({});
    const appContext = useContext(AuthContext);
    const [feedBackModal, setFeedBackModal] = useState(false);
    const roles: string[] | undefined = appContext?.roles ? appContext?.roles : [];
    const [language, setlanguage] = useState("en");
    const [comment, setComment] = useState("");
    const [emptyMessage, setEmptyMessage] = useState(false)
    let isAdmin = false;
    for (const admin of roles) {
        if (admin == "ROLE_COMPANY_ADMIN") {
            isAdmin = true;
        }
    }
    const mockdata = [
        //{ icon: IconHome2, label: 'Home', route: '/' },
        { icon: IconGauge, label: 'Dashboard', route: '/dashboard' },
        { icon: IconBriefcase, label: i18n.t("Company"), route: '/company' },
        
        { icon: IconUser, label: i18n.t("Account"), route: '/account' },
        //{ icon: IconFingerprint, label: 'Security', route: '/security' },
        //{ icon: IconSettings, label: 'Settings', route: '/settings' },
    ];
    if (isAdmin) {
        mockdata.push({icon: IconArticle, label: "Partner", route: '/partner' })
    }


    const links = mockdata.map((link, index) => (
        <NavbarLink
            {...link}
            key={link.label}
            active={index === active}
            onClick={() => {
                setActive(index);
                navigate(link.route)
            }}
        />
    ));

    return (
        <Navbar width={{ base: 80 }} height="100vh" p="md" style={{
            zIndex: 200,
        }}>
            <Modal opened={feedBackModal}
                onClose={() => setFeedBackModal(false)}
                centered
                title={i18n.t("TitleModalFeedBack")}
                withCloseButton={false}>
                <Textarea
                    placeholder={i18n.t("Comment") || ""}
                    label={i18n.t("Comment") || ""}
                    radius="xs"
                    withAsterisk
                    value={comment}
                    onChange={(event) => setComment(event.currentTarget.value)}
                    error={comment ? false : emptyMessage ? true : false}
                />
                <Button fullWidth onClick={
                    () => {
                        if (comment.length > 0) sendFeedback({
                            comment: comment,
                        })
                        else setEmptyMessage(true)
                    }
                }>
                    {i18n.t("submit")}
                </Button>
            </Modal>
            <Center>
                <img src={appContext?.company?.companyLogo ? appContext?.company?.companyLogo : logo} alt="" width={appContext?.company?.companyLogo ? "70px" : "40px"} />
            </Center>
            <Navbar.Section grow mt={50}>
                <Stack justify="center" spacing={0}>
                    {links}
                </Stack>
            </Navbar.Section>
            <Navbar.Section>
                <Stack justify="center" spacing={0}>

                    <NavbarLink icon={IconLanguage} label={language == "en" ? "English" : "Français"} onClick={() => {
                        setlanguage(language == "en" ? "fr" : "en")
                        i18n.changeLanguage(language)
                        localStorage.setItem("lng", language);
                    }} />
                    <NavbarLink icon={IconLogout} label={i18n.t("logout")} onClick={() => {
                        localStorage.removeItem("token")
                        window.location.reload();
                    }} />


                    <p></p>
                    <NavbarLink icon={IconHelp} label={i18n.t("FeedBack") + " V" + process.env.REACT_APP_VERSION} onClick={() => {
                        setFeedBackModal(true);
                    }} />
                </Stack>
            </Navbar.Section>
        </Navbar>

    );
}