import axios from "axios";
import { PATH } from "../constants/paths";

export function removeItem(itemToRemove: string) {
    window.localStorage.removeItem(itemToRemove);
}

export function getItem(item: string) {
    return window.localStorage.getItem(item);
}

export function addItem(localStorageName: string, newItem: string) {
    window.localStorage.setItem(localStorageName, newItem);
}

export function checkJwtExpiration() {
    const jwt: any = getItem("token");
    // Récupère le troisième segment du jeton (payload), qui contient les données décodées
    const payloadSegment = jwt.split('.')[1];
    // Décode les données du segment de payload en utilisant la fonction `atob`
    const payload = JSON.parse(atob(payloadSegment));
    // Récupère la propriété `exp` (expiration) du payload
    const expirationTime = payload.exp;
    // Vérifie si le temps actuel est supérieur au temps d'expiration
    //return Date.now() / 1000 > expirationTime;

    if (Date.now() / 1000 > expirationTime) {
        removeItem("token")
        window.location.reload();
    }
}

export function formaterTextResponseAI(entree: string) {
    let code = false;
    let resultat = "";
    const lignes = entree.split("\n");
    for (const ligne of lignes) {
        if (ligne.startsWith("```")) {
            if (code) {
                resultat += "</code></pre>";
                code = false;
            } else {
                resultat += "<pre><code>";
                code = true;
            }
        } else if (code) {
            resultat += ligne + "\n";
        } else {
            resultat += "<p>" + ligne + "</p>";
        }
    }
    if (code) {
        resultat += "</code></pre>";
    }
    return resultat;
}

export function truncateString(str: string, num: number) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
}



function getFileNameFromUrl(url: string) {
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
}

 
export function getFileExtension(fileName: string) {
    const parts = fileName.split(".");
    return parts[parts.length - 1];
}




export function getFileTypeForm(url: string) {
    checkJwtExpiration();
    const response = axios
        .post(PATH.API + "typeformdata/getFile", { url: url }, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
    return response;
}

/* Create async function 
export async function uploadByFile(file: any) {
    const formData = new FormData()
    formData.append('file', file)
    const result = await axios.post('https://localhost:8000/uploadFile', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return Promise.resolve(result.data);
}
*/
export function getAnswers(json: any) {
    var answers = [];

    if (json && json.items && json.items.length > 0) {
        var items = json.items;

        for (var i = 0; i < items.length; i++) {
            var item = items[i];

            if (item.answers) {
                var itemAnswers = item.answers;

                for (var j = 0; j < itemAnswers.length; j++) {
                    var answer = itemAnswers[j];
                    var field = answer.field;
                    var fieldData = {
                        id: field.id,
                        type: field.type,
                        label: ""
                    };

                    if (fieldData.type === "multiple_choice" && answer.choice && answer.choice.label) {
                        fieldData.label = answer.choice.label;
                        answers.push(fieldData);
                    } else if (fieldData.type === "long_text" && answer.text) {
                        fieldData.label = answer.text;
                        answers.push(fieldData);
                    } else if (fieldData.type === "file_upload" && answer.file_url) {
                        fieldData.label = answer.file_url;
                        answers.push(fieldData);
                    }
                }
            }
        }
    }
    return answers;
}


export async function createBlockEditorJS(answers: any) {

    const answer1: any = answers[0];
    const answer2: any = answers[1];
    const answer3: any = answers[2];
    const answer4: any = answers[3];
    const answer5: any = answers[4] ? answers[4] : null;

    const blockEditorJS: any =
        [
            {
                "id": "Category",
                "type": "header",
                "data": {
                    "text": "Catégorie",
                    "level": 2
                },

            },
            {
                "id": answers[0].id,
                "type": "table",
                "data": {
                    "withHeadings": false,
                    "content": [
                        [
                            answers[0].label,
                            answers[1].label,
                            answers[2].label,
                        ]
                    ]
                }
            },
            {
                "id": "DescriptionText",
                "type": "header",
                "data": {
                    "text": "Description",
                    "level": 2
                },

            },
            {
                "id": "Description",
                "type": "paragraph",
                "data": {
                    "text": answers[3].label
                },

            }
        ];

    if (answers[4]) {
        await getFileTypeForm(answers[4].label).then((response: any) => {

            blockEditorJS.push(
                {
                    "id": "PieceJointeText",
                    "type": "header",
                    "data": {
                        "text": "Piece jointe",
                        "level": 2
                    },

                },
                {
                    "id": "PieceJointe",
                    "type": "attaches",
                    "data": {
                        "file": {
                            "url": response.data,
                            "name": getFileNameFromUrl(response.data),
                            "extension": getFileExtension(getFileNameFromUrl(response.data))
                        },
                        "title": getFileNameFromUrl(response.data)
                    },
                });
        });
        const block = generateObjectEditorJs(blockEditorJS);
        return { answer1, answer2, answer3, answer4, answer5, block };
    } else {
        const block = generateObjectEditorJs(blockEditorJS);

        return { answer1, answer2, answer3, answer4, block };
    }

}


/**
 * Permet de cree un objet type pour EditorJs 
 * @param obj 
 * @returns obj a sauvegarder pour pouvoir afficher les donnée sur l'editeur 
 */
export function generateObjectEditorJs(obj: any) {

    const objEditorJs = {
        "time": 1668032645006,
        "blocks": obj,
        "version": "2.50.0"
    }


    return JSON.stringify(objEditorJs);
}

export function replaceChar(string: string, char: string, replacement: string) {
    return string.split(char).join(replacement);
}


export function convertJsonToCsvAndDownload(json: any, fileName: string) {

    const jsonCSV : any = [];
    json.forEach((element: any) => {
        jsonCSV.push({
            "id": element.id,
            "title": element.title,
            "fullName": element.fullName,
            "groupName": element.groupName,
            "status": element.status,
            "createdAt": element.date.date,
        })
    });


    console.log(getAnswers(json))

    const replacer = (key: any, value: any) => value === null ? '' : value;
    const header = Object.keys(jsonCSV[0]);
    let csv = jsonCSV.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}